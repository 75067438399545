import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PageLayoutComponent } from '@shared/layouts/page-layout/page-layout.component';
import { PAGES_ROUTES } from './pages.routes';
import { NavigationScrollService } from '@shared/service/navigation-scroll/navigation-scroll.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    standalone: true,
    imports: [PageLayoutComponent],
})
export class PagesComponent implements OnInit, OnDestroy {
    private _navigationScrollService = inject(NavigationScrollService);

    public routes = PAGES_ROUTES;

    ngOnInit(): void {
        this._navigationScrollService.ngOnInit();
    }

    ngOnDestroy(): void {
        this._navigationScrollService.ngOnDestroy();
    }
}
