import { Routes } from '@angular/router';
import { canActivateChildFn, canActivateFn } from '@core/service/auth/guard';
import { PagesComponent } from '@maPages/pages.component';

export const APP_ROUTES: Routes = [
    {
        path: 'auth',
        title: 'Авторизация',
        loadComponent: () =>
            import('@maPages/auth/auth.component').then((m) => m.AuthComponent),
    },
    {
        path: '',
        title: 'Личный кабинет',
        component: PagesComponent,
        canActivate: [canActivateFn],
        canActivateChild: [canActivateChildFn],
        loadChildren: () =>
            import('@maPages/pages.routes').then((mod) => mod.PAGES_ROUTES),
        data: {
            permissionId: 'everyone',
        },
    },
    {
        path: '**',
        redirectTo: '/',
    },
];
